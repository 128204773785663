import Swiper, { Navigation, Scrollbar } from 'swiper';

Swiper.use([Navigation, Scrollbar]);

export default class ProductSlider {
    constructor(element) {
        this.$el = element;
        this.initSlider();
    }

    initSlider() {
        // eslint-disable-next-line no-new
        new Swiper(this.$el, {
            direction: 'horizontal',
            slidesPerView: 'auto',
            threshold: 20,
            freeMode: true,
            spaceBetween: 16,
            keyboard: {
                enabled: true,
                onlyInViewport: false,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            scrollbar: {
                el: '.swiper-scrollbar',
                hide: false,
                draggable: true,
            },
        });
    }
}

const sliders = document.querySelectorAll('[data-product-slider]');
if (sliders) {
    sliders.forEach(slider => {
        // eslint-disable-next-line no-new
        new ProductSlider(slider);
    });
}
